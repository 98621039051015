/************************************
 *   IMPORTS
 ************************************/


// Import settings
@import "variables";


// Bootstrap
$icon-font-path: '../node_modules/bootstrap-sass/assets/fonts/bootstrap/';
@import "../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss";

// Font awesome
//$fa-font-path: "../node_modules/font-awesome/fonts/";
//@import "../node_modules/font-awesome/scss/font-awesome";
